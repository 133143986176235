<template>
  <div>
    <b-overlay :show="isFetching">
      <b-card>
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h6>
            {{ `${$t('fields.result')} (${boxes.length})` }}
          </h6>
          <b-button variant="primary" size="sm" @click="navigateRoute('')">
            <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add') }}
          </b-button>
        </div>

        <b-table :items="boxes" :fields="fields" responsive show-empty>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(title)="data">
            {{ data.value || '-' }}
          </template>
          <template #cell(startDate)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(endDate)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(isActive)="data">
            <b-badge :variant="data.value ? 'success' : 'danger'">
              {{
                data.value
                  ? $t('utils.status.active')
                  : $t('utils.status.inactive')
              }}
            </b-badge>
          </template>
          <template #cell(id)="data">
            <b-dropdown variant="info" size="sm" dropleft>
              <template #button-content>
                <i class="uil uil-cog"></i>
              </template>
              <b-dropdown-item
                variant="warning"
                @click="onChangeBoxStatus(data.value)"
              >
                {{ $t('buttons.change_status') }}
              </b-dropdown-item>
              <b-dropdown-item @click="navigateRoute(data.value)">
                <i class="uil uil-edit-alt mr-2"></i>
                {{ $t('buttons.edit') }}
              </b-dropdown-item>
              <b-dropdown-item variant="danger" @click="onDelete(data.value)">
                <i class="uil uil-trash-alt mr-2"></i>
                {{ $t('buttons.remove') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{
              $t('messages.nothing_here')
            }}</p>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'RewardBoxes',
  props: {
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fields: [
        '#',
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'title',
          label: `${this.$t('fields.title')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'startDate',
          label: `${this.$t('rewards.start_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'endDate',
          label: `${this.$t('rewards.end_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'requiredCoupons',
          label: `${this.$t('rewards.use_coupon')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'isActive',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
        },
        {
          key: 'id',
          label: '-',
          width: '100',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.reward.isFetchingBoxes,
      isDeleting: (state) => state.reward.isDeletingBox,
    }),
    ...mapGetters(['boxes']),
  },
  watch: {
    agentId(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchBoxes', 'deleteBox', 'toggleBoxStatus']),
    fetchData() {
      if (this.agentId) {
        this.fetchBoxes(this.agentId)
      }
    },
    onDelete(boxId) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteBox({
              agentId: this.agentId,
              id: boxId,
            })
          }
        })
    },
    navigateRoute(id) {
      if (id) {
        this.$router.push(`/rewards/detail/${id}?agentId=${this.agentId}`)
      } else {
        this.$router.push(`/rewards/create?agentId=${this.agentId}`)
      }
    },
    onChangeBoxStatus(boxId) {
      this.toggleBoxStatus({ agentId: this.agentId, id: boxId })
    },
  },
}
</script>
